import React, { useRef, useState } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import "../styles/360-catalog.scss";
import "../styles/auto.scss";
import "../styles/furniture.scss";
import CommonSection from "../components/CommonSection";
import "bootstrap/dist/css/bootstrap.min.css";
import ClientLogoBanner from "../components/ClientLogoBanner";
import StatColumn from "../components/StatColumn";
import ButtonBanner from "../components/ButtonBanner";
import AccordionSection from "../components/AccordionSection";
import Testimonials from "../sections/homepage/Testimonials";
import Blogs from "../sections/homepage/Blogs";
import { Container } from "react-bootstrap";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import CaseStudy from "../sections/homepage/CaseStudy";

const HomeFurnishing = () => {
  const caseStudies = [
    {
      image: "../../images/casestudy/codesustain.webp",
      title: "360 experience for ecommerce platform",
      footer: "",
      link: "/codesustain",
    },
    {
      image: "../../images/casestudy/craftmaestro.webp",
      title: "Experiential buying for handicrafts",
      footer: "",
      link: "/craft-maestro",
    },
    {
      image: "../../images/casestudy/onlymat.webp",
      title: "Reimagining doormats industry",
      footer: "",
      link: "/onlymat",
    },
  ];
  let logoList = [
    "../../images/trustedclients/artevenue.svg",
    "../../images/trustedclients/terrainart.svg",
    "../../images/trustedclients/atypicaladvantage.svg",
    "../../images/trustedclients/rugberry.svg",
    "../../images/trustedclients/craftmaestro.svg",
    "../../images/trustedclients/codesustain.svg",
  ];
  const adopt = [
    {
      image: "../../images/homefurnishing/ar-rugs.png",
      imageWebp: "../../images/homefurnishing/ar-rugs.webp",
      title: "Instant 3D renders",
      sub: "Turn your 2D images into 3D images in seconds with our software.",
    },

    {
      image: "../../images/homefurnishing/ar-rugs.png",
      imageWebp: "../../images/homefurnishing/ar-rugs.webp",
      title: "Virtual reality & augmented reality",
      sub: "Let people visualize life-size products in their personal or virtual spaces.",
    },
  ];

  const advantages = [
    {
      image:
        "../../images/homefurnishing/augmented-reality-home-improvement.png",
      imageWebp:
        "../../images/homefurnishing/augmented-reality-home-improvement.webp",
      title: "Scale with speed",
      sub: "Bulk convert all your 2D images to 3D in under a few seconds.",
    },
    {
      image:
        "../../images/homefurnishing/augmented-reality-home-improvement.png",
      imageWebp:
        "../../images/homefurnishing/augmented-reality-home-improvement.webp",
      title: "Get started in minutes",
      sub: "Our software can be installed seamlessly on your website in a few minutes.",
    },
    {
      image:
        "../../images/homefurnishing/augmented-reality-home-improvement.png",
      imageWebp:
        "../../images/homefurnishing/augmented-reality-home-improvement.webp",
      title: "Cloud storage",
      sub: "Our servers stores images on the cloud servers & complies with Google website speed guidelines.",
    },
    {
      image:
        "../../images/homefurnishing/augmented-reality-home-improvement.png",
      imageWebp:
        "../../images/homefurnishing/augmented-reality-home-improvement.webp",
      title: "Easy distribution",
      sub: "Download as many images as you want and distribute on any platform.",
    },
    {
      image:
        "../../images/homefurnishing/augmented-reality-home-improvement.png",
      imageWebp:
        "../../images/homefurnishing/augmented-reality-home-improvement.webp",
      title: "Sales intelligence",
      sub: "Get insights around the most viewed & interacted products to improve on your marketing strategy.",
    },
    {
      image:
        "../../images/homefurnishing/augmented-reality-home-improvement.png",
      imageWebp:
        "../../images/homefurnishing/augmented-reality-home-improvement.webp",
      title: "24/7 customer support",
      sub: "Our team is always available. We believe in creating value.",
    },
  ];

  return (
    <Layout>
      <Helmet>
        <title>HelloAR: 3D Carpet| Augmented Reality Carpet| AR in Art</title>
        <meta
          name="description"
          content="Our proprietary app helps you create 3D carpet and Augmented Reality for carpet, rugs, art, art galleries in seconds."
        />
        <meta
          name="keywords"
          content="augmented reality carpet, rug ar, ar rugs, carpet ar app, 3d carpet, ar in art, augmented art"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta property="og:image" content="../../images/auto/car_360.jpg" />
      </Helmet>

      <Container fluid className="furniture">
        <div className="spacer"></div>
        <div className="spacer d-xl-none"></div>
        <div className="spacer-sm"></div>
        <div className="section-1">
          <CommonSection
            title="Enable experiential buying experiences"
            sub=" Drive conversions & engagements by enabling users to see, feel, try, and interact with your products and their features in detail."
            buttonText="REQUEST A DEMO"
          >
            <VideoPlayer
              videoUrl="../../../videos/augmented-reality-carpet.mp4"
              videoThumbnail="../../images/thumbnail/home-furnishing.webp"
            />
          </CommonSection>
        </div>
        <div className="spacer-sm"></div>
        <div className="section-2">
          {/* <CaseStudy caseStudies={caseStudies} /> */}
          {/* <ClientLogoBanner logoList={logoList} /> */}
        </div>
        <div className="spacer" />
        <AccordionSection
          items={adopt}
          title="Infuse life to your products"
          mainIndex={2}
        />
        <div className="spacer" />
        <StatColumn
          title="Why AR/VR solution"
          items={[
            {
              stat: "250%",
              info: "Increase in conversions",
            },

            {
              stat: "40%",
              info: "Fewer returns",
            },
            {
              stat: "66%",
              info: "Customers prefer AR to shop",
            },
          ]}
        />
        <div className="spacer" />
        <ButtonBanner buttonTitle="REQUEST A DEMO" buttonUrl="/contact-us">
          <h1 className="h1 text-center text-light px-3">
            One stop solution.
            <br />
            Zero upfront cost. Pay as you go.
          </h1>
        </ButtonBanner>
        <div className="spacer"></div>
        <AccordionSection
          title="HelloAR advantages"
          items={advantages}
          mainIndex={3}
        />
        <div className="spacer" />
        <Testimonials />
        <div className="spacer" />
        <ClientLogoBanner logoList={logoList} />

        {/* <CaseStudy /> */}
        {/* <div className="spacer"></div> */}
        {/* <Blogs /> */}
        {/* <div className="spacer"></div> */}
      </Container>
    </Layout>
  );
};

export default HomeFurnishing;
